import { Tile, TileOpts } from '../components/tile'

import img1 from '../assets/pages/about/IMG_3815_2.jpg'
import img2 from '../assets/pages/about/IMG_3884.jpg'
import img3 from '../assets/pages/about/IMG_3800.jpg'

import { Button, createStyles } from '@mantine/core'
import { Splash, splashClassProps, SplashTitle } from '../components/splash'
import { AngularStyle } from '../components/angular-style'
import { Link } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
  splash: {
    ...splashClassProps,
    [theme.fn.smallerThan('sm')]: {
      backgroundPositionX: '-200px !important',
    },
  },

  intro: {
    padding: '2em 8em',
    [theme.fn.smallerThan('lg')]: {
      padding: '2em 6em',

      [theme.fn.smallerThan('sm')]: {
        padding: theme.spacing.sm,
      },
    },
  },

  whyMofWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.colors.dark[9],
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  whyMofTextSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.xl,
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    },
    flex: '4.5 1 0%',
    gap: theme.spacing.md,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  whyMofHeading: {},

  whyMofContent: {},

  whyMofImage: {
    flex: '1 0 0%',
    overflow: 'auto',
    height: 'auto',
    minHeight: 'auto',
    minWidth: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  whyTrainWithMeContent: {
    padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    },
  },

  firstStepsContent: {
    padding: `${theme.spacing.sm}px ${theme.spacing.xl}px`,
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    },
  },
}))

export const About = (): JSX.Element => {
  const aboutTileOpts: TileOpts = {
    style: 'md-media-1',
    colour: 'bg-black',
    accent: 'brand-secondary',
    greyscaleMedia: false,
    avatar: img1,
    avatarSize: 'xl',
  }

  const myServicesTileOpts: TileOpts = {
    style: 'simple-3',
    colour: 'bg-secondary',
    accent: 'bg-primary',
  }

  const whyTrainWithMeTileOpts: TileOpts = {
    style: 'simple-1',
    colour: 'bg-primary',
    accent: 'brand-tertiary',
  }

  const { classes } = useStyles()

  const aboutP1 =
    // eslint-disable-next-line quotes
    'I’m Rik, a personal trainer in Coventry specialising in calisthenics and weight gain training, with nearly 10 years of experience helping people start their fitness journeys, master muscle-ups, and run their first 5K.'

  const aboutP2 =
    // eslint-disable-next-line quotes
    `To me, fitness isn’t about six-packs or lifting the heaviest weights—it’s about feeling confident, strong,
and healthy in your body and mind. It's about not running out of breath when playing with your kids,
walking up the stairs pain-free, looking at yourself in the mirror and being able to smile and feel happy
with how you look and feel.`

  const aboutP3 =
    // eslint-disable-next-line quotes
    'I had always been a skinny guy, having been bullied, and suffering from depression and a lack of self-confidence in the past, but fitness gave me the tools to grow stronger in every way.'

  const aboutP4 =
    // eslint-disable-next-line quotes
    'Now, I’m here to help you do the same. If you need a local fitness trainer in Coventry, contact me today.'

  return (
    <div className='about-page'>
      <title>About Me | Coventry Personal Trainer | Mistry of Fitness</title>
      <meta
        name='description'
        content='Personal trainer | Build muscle with weight gain plans, calisthenics coaching & strength training | Start your fitness journey today →'
      />
      <Splash
        height={'calc(100vh - 56px)'}
        backgroundSrc={`url(${img2})`}
        className={classes.splash}
      >
        <SplashTitle>
          About Mistry of Fitness: <br />
          Expert Strength Trainer in Coventry
        </SplashTitle>
      </Splash>
      <div className='about-tile'>
        <Tile tileOpts={aboutTileOpts} mh={200}>
          <p className={classes.intro}>
            {aboutP1}
            <h2>My Story</h2>
            {aboutP2}
            <br />
            <br />
            {aboutP3}
            <br />
            <br />
            {aboutP4}
            <br />
            <br />
            <Link to='/contact-faqs' style={{ margin: '1.2em' }}>
              <Button
                variant='filled'
                radius='xl'
                size='md'
                styles={{
                  root: { padding: '0 14', height: 48 },
                }}
              >
                Contact Now
              </Button>
            </Link>
          </p>
        </Tile>

        <Tile tileOpts={myServicesTileOpts} mh={200}>
          <h2>My Services?</h2>

          <ul>
            <li>
              <strong>Personal Trainer for Beginners</strong>: Gentle guidance to build confidence
              and establish a strong foundation.
            </li>
            <li>
              <strong>Weight Gain Plans</strong>: Structured programmes designed to help you gain
              lean muscle.
            </li>
            <li>
              <strong>Strength Training</strong>: Tailored exercises to build power and endurance.
            </li>
            <li>
              <strong>Salsa Dancing</strong>: My salsa dance classes are a fun and social way to
              improve your fitness. Whether you’re a beginner or experienced dancer, salsa enhances
              coordination, boosts stamina, and promotes overall well-being.
            </li>
            <li>
              <strong>Calisthenics Workout Plans</strong>: I have special expertise in calisthenics
              training and love teaching calisthenics for beginners.
            </li>
          </ul>
        </Tile>

        <div className={classes.whyMofWrapper}>
          <div>
            <AngularStyle
              className='test'
              colour='bg-secondary'
              style={3}
              side={'left'}
              size={'sm'}
              height={'50px'}
              width={'100%'}
            ></AngularStyle>

            <div className={classes.whyTrainWithMeContent}>
              <h2>Why Train With Me?</h2>

              <ul style={{ padding: '0 2em' }}>
                <li>
                  <strong>Personalised Plans</strong>: Every session is built around your specific
                  goals. Take a look at the available packages and pricing{' '}
                  <Link to='/packages'>here</Link>.
                </li>
                <li>
                  <strong>Convenient Location</strong>: If you’re looking for a personal trainer in
                  Coventry - I offer training sessions at Simply Gym near Costco.
                </li>
                <li>
                  <strong>Empathy and Understanding</strong>: I’ve been through the challenges
                  myself and know how to guide you through them. If you are struggling with weight
                  management, I offer both fitness coaching as well as weight gain plans to increase
                  your muscle mass.
                </li>
              </ul>
            </div>
          </div>

          <div className={classes.whyMofImage}>
            <img
              src={img3}
              style={{
                alignSelf: 'center',
                height: '100%',
                overflow: 'auto',
                objectFit: 'cover',
              }}
            ></img>
          </div>
        </div>

        <Tile tileOpts={whyTrainWithMeTileOpts} mh={200}>
          <h2>Frequently Asked Questions</h2>

          <ul>
            <li>
              <strong>Is personal training suitable for beginners?</strong>: Absolutely! My
              programmes are designed to build confidence and fitness safely.
            </li>
            <li>
              <strong>Can you help with weight gain goals?</strong>: Yes, I offer structured plans
              to help you gain strength and lean muscle.
            </li>
            <li>
              <strong>How do I get started?</strong>: Get in touch, and we’ll arrange an initial
              consultation.
            </li>
            <li>
              <strong>What is Calisthenics?</strong>: Calisthenics is a form of exercise that uses
              your body weight as resistance to build strength, flexibility, and endurance. It
              includes movements like push-ups, pull-ups, squats, and core exercises, requiring
              minimal equipment and offering maximum results.
            </li>
          </ul>
        </Tile>

        <div>
          <AngularStyle
            className='test'
            colour='brand-secondary'
            style={2}
            side={'left'}
            size={'sm'}
            height={'50px'}
            width={'100%'}
          ></AngularStyle>

          <div className={classes.firstStepsContent}>
            <h2>Take the First Step Towards a Healthier You</h2>

            <p>
              Fitness has changed my life, and I’m here to help it change yours too. Let’s build
              strength, confidence, and a healthier future together.
            </p>
            <br />
            <p>Get in touch with me today at Mistry of Fitness and let’s get started!</p>

            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link to='/contact-faqs' style={{ margin: '1.2em' }}>
                <Button
                  variant='filled'
                  radius='xl'
                  size='md'
                  styles={{
                    root: { padding: '0 14', height: 48 },
                  }}
                >
                  Contact Now
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
