import { createStyles } from '@mantine/core'
import { Splash, SplashContent, SplashTitle } from '../../components/splash'
import {
  carouselImages,
  HomeSplashText,
  ShortAboutTile,
  ShortContactFaqsTile,
  ShortPackagesTile,
  ShortTestimonialsTile,
} from './home.constants'

const useStyles = createStyles((theme) => ({
  splash: {
    height: 'calc(100vh - (56px + 28px))',

    [theme.fn.smallerThan('sm')]: {
      height: 'calc(100vh - (56px + 18px))',
    },
  },
}))

export const Home = (): JSX.Element => {
  const { classes } = useStyles()
  return (
    <div className='home'>
      <Splash className={classes.splash} carouselImages={carouselImages}>
        <SplashTitle>Personal Training in Coventry</SplashTitle>

        <SplashContent>
          <HomeSplashText></HomeSplashText>
        </SplashContent>
      </Splash>

      <ShortAboutTile></ShortAboutTile>

      <ShortPackagesTile></ShortPackagesTile>

      <ShortTestimonialsTile></ShortTestimonialsTile>

      <ShortContactFaqsTile></ShortContactFaqsTile>
    </div>
  )
}
