/* eslint-disable quotes */
import { Accordion, Container, createStyles } from '@mantine/core'

const faqs: { question: string; answer: JSX.Element }[] = [
  {
    question: 'Where are you located?',
    answer: (
      <div>
        <span style={{ fontStyle: 'italic' }}>
          <p>Simply Gym</p>
          <p>Earl Place Business Park</p>
          <p>Fletchamstead Highway</p>
          <p>Coventry</p>
          <p>CV4 9XL</p>
        </span>
      </div>
    ),
  },
  {
    question: 'How do I book a free consultation or package with yourself?',
    answer: (
      <div>
        <p>Simply drop me a message on what you are interested in and I will respond ASAP.</p>
      </div>
    ),
  },
  {
    question: 'Do you only train people wanting to learn calisthenics?',
    answer: (
      <div>
        <p>
          {
            "No! Whatever your goal and whatever stage you're at all sessions and programmes will be tailored to your requirements. You are more likely to succeed with a programme if you enjoy the program and exercises within it, which is what I aim to provide."
          }
        </p>
      </div>
    ),
  },
  {
    question: 'Do I have to be a member of the gym?',
    answer: (
      <div>
        <p>
          {
            'Yes. Any 1 to 1 sessions will be based at Simply gym, if you are not already a member you will need to join. However please contact myself first as I may be able to offer a discount to your membership.'
          }
        </p>
      </div>
    ),
  },
]

const useStyles = createStyles((theme) => ({
  faqAnswer: {
    padding: theme.spacing.lg,

    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.xs,
    },
  },
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,

    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.xs,
    },
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
    textAlign: 'center',
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}))

export const Faqs = (): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Container size='sm' className={classes.wrapper}>
      <h2 className={classes.title}>Frequently Asked Questions</h2>

      <Accordion variant='separated'>
        {faqs.map((faq) => (
          <Accordion.Item key={faq.question} className={classes.item} value={faq.question}>
            <Accordion.Control>{faq.question}</Accordion.Control>
            <Accordion.Panel className={classes.faqAnswer}>{faq.answer}</Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  )
}
