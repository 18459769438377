/* eslint-disable react/no-unescaped-entities */
import { Tile, TileOpts } from '../components/tile'

import img2 from '../assets/pages/services/IMG_3881.jpg'

// service images
import img3 from '../assets/pages/services/personalTraining.jpg'
import img4 from '../assets/pages/services/IMG_3852.jpg'
import img5 from '../assets/pages/services/IMG_3847.jpg'
import salsa from '../assets/pages/services/17931.jpg'

import { Button, createStyles } from '@mantine/core'
import { Splash, splashClassProps, SplashTitle } from '../components/splash'
import { AngularStyle } from '../components/angular-style'
import { Link } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
  splash: {
    ...splashClassProps,
    [theme.fn.smallerThan('sm')]: {
      backgroundPositionX: '-200px !important',
    },
  },

  intro: {
    padding: '2em 8em',
    [theme.fn.smallerThan('lg')]: {
      padding: '2em 6em',

      [theme.fn.smallerThan('sm')]: {
        padding: theme.spacing.sm,
      },
    },
  },

  bottomTile: {
    padding: '0 8em',

    [theme.fn.smallerThan('lg')]: {
      padding: '0 2em',
    },
  },

  whyMofTextSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.xl,
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    },
    flex: '4.5 1 0%',
    gap: theme.spacing.md,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  whyMofHeading: {},

  whyMofContent: {},

  whyMofImage: {
    flex: '1 0 0%',
    overflow: 'auto',
    height: 'auto',
    minHeight: 'auto',
    minWidth: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  whyTrainWithMeContent: {
    padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    },
  },

  firstStepsContent: {
    padding: `${theme.spacing.sm}px ${theme.spacing.xl}px`,
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    },
  },

  seePackagesBtn: {
    color: theme.white,
    backgroundColor: theme.colors.brand['1'],
  },

  tileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
  },

  service1: {
    padding: '2em 8em',
    [theme.fn.smallerThan('lg')]: {
      padding: '2em 6em',

      [theme.fn.smallerThan('sm')]: {
        padding: theme.spacing.sm,
      },
    },
  },
}))

export const Services = (): JSX.Element => {
  const service1TileOpts: TileOpts = {
    style: 'md-media-1',
    colour: 'bg-secondary',
    accent: 'brand-secondary',
    greyscaleMedia: false,
    avatar: img3,
    avatarSize: 'md',
  }

  const service2TileOpts: TileOpts = {
    style: 'md-media-2',
    colour: 'brand-tertiary',
    accent: 'brand-secondary',
    greyscaleMedia: false,
    avatar: img4,
    avatarSize: 'md',
  }

  const service3TileOpts: TileOpts = {
    style: 'md-media-1',
    colour: 'bg-secondary',
    accent: 'brand-secondary',
    greyscaleMedia: true,
    avatar: img5,
    avatarSize: 'md',
  }

  const service4TileOpts: TileOpts = {
    style: 'md-media-2',
    colour: 'brand-tertiary',
    accent: 'brand-secondary',
    greyscaleMedia: false,
    avatar: salsa,
    avatarSize: 'md',
  }

  const { classes } = useStyles()

  return (
    <div className='services-page'>
      <title>My Services | Coventry Fitness Training | Mistry of Fitness</title>
      <meta
        name='description'
        content='Tailored personal training| Calisthenics for beginners, weight gain plans, and salsa dance classes | Start your fitness journey today →'
      />

      <Splash
        height={'calc(100vh - 56px)'}
        backgroundSrc={`url(${img2})`}
        className={classes.splash}
      >
        <SplashTitle>Coventry Personal Training Services</SplashTitle>
      </Splash>

      <div className='services-tile'>
        <p className={classes.intro}>
          I am a PT in Coventry specialising in personalised fitness coaching, calisthenics for
          beginners, weight gain plans, and even salsa dancing classes. My goal is to offer a range
          of training options that make fitness enjoyable, effective, and tailored to your goals
        </p>

        <div className={classes.tileWrapper}>
          <Tile tileOpts={service1TileOpts} mh={200}>
            <p className={classes.service1}>
              <h2>Personal Training</h2>

              <p>
                With one-on-one PT sessions, I focus on creating a plan that works specifically for
                you. Whether you're starting your fitness journey or aiming to push your limits,
                every session is designed to maximise your progress. I operate out of Simply Gym
                Coventry so if you need a personal trainer in Coventry please get in touch.
              </p>
            </p>
          </Tile>
        </div>

        <div className={classes.tileWrapper}>
          <Tile tileOpts={service2TileOpts} mh={200}>
            <p className={classes.service1}>
              <h2>Calisthenics Coaching</h2>

              <p>
                If you're curious about calisthenics for beginners or want a structured calisthenics
                workout plan, I’m here to guide you. Calisthenics builds strength, flexibility, and
                control using your body weight, making it a versatile and effective training method.
              </p>
            </p>
          </Tile>
        </div>

        <div className={classes.tileWrapper}>
          <Tile tileOpts={service3TileOpts} mh={200}>
            <p className={classes.service1}>
              <h2>Weight Gain Coaching</h2>

              <p>
                For those struggling to gain weight or build muscle, my weight gain plans focus on
                sustainable nutrition and strength training. I’ll help you create a plan that’s
                effective and tailored to your needs.
              </p>
            </p>
          </Tile>
        </div>

        <div className={classes.tileWrapper}>
          <Tile tileOpts={service4TileOpts} mh={200}>
            <p className={classes.service1}>
              <h2>Salsa Dancing Classes</h2>

              <p>
                Fitness doesn’t have to mean weights and gym sessions. My salsa dance classes near
                me offer a fun, social, and energetic way to stay fit. Suitable for all experience
                levels, salsa improves coordination, stamina, and overall fitness.
              </p>
            </p>
          </Tile>
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link to='/packages' style={{ margin: '1.2em' }}>
            <Button
              variant='filled'
              radius='xl'
              size='md'
              className={classes.seePackagesBtn}
              styles={{
                root: { padding: '0 14', height: 48 },
              }}
            >
              See My Packages
            </Button>
          </Link>
        </div>
        {/* </Tile> */}

        <div>
          <AngularStyle
            className='test'
            colour='bg-primary'
            style={3}
            side={'left'}
            size={'sm'}
            height={'80px'}
            width={'100%'}
          ></AngularStyle>
          <div className={classes.bottomTile}>
            <h2>Why Train with Me?</h2>

            <ul>
              <li>Tailored fitness plans designed around your goals.</li>
              <li>Flexible scheduling locally at Simply Gym Coventry.</li>
              <li>
                Support and encouragement every step of the way. Looking for a personal trainer for
                beginners? I can certainly help!
              </li>
            </ul>

            <h2>Get Started Today!</h2>

            <p>
              Whether you're looking for a local personal trainer in Coventry, a fitness trainer to
              help you gain muscle mass, or just want to try something new with salsa dance classes,
              I’m here to help.
            </p>

            <p>Get in touch today and let’s build a fitness plan that works for you!</p>

            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link to='/contact-faqs' style={{ margin: '1.2em' }}>
                <Button
                  variant='white'
                  radius='xl'
                  size='md'
                  styles={{
                    root: { padding: '0 14', height: 48 },
                  }}
                >
                  Get Started
                </Button>
              </Link>
            </div>
          </div>
          <AngularStyle
            className='test'
            colour='brand-secondary'
            style={3}
            side={'right'}
            size={'sm'}
            height={'80px'}
            width={'100%'}
          ></AngularStyle>
        </div>
      </div>
    </div>
  )
}
