import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { About } from './pages/about'

import { Home } from './pages/home/home'
import { Packages } from './pages/packages/packages'
import { Testimonials } from './pages/testimonials'
import { Toolbar } from './components/toolbar'
import { toolbarImage } from './pages/home/home.constants'
import { ContactFaqs } from './pages/contact-faqs/contact-faqs'
import { MofFooter } from './components/footer'
import { PrivacyPolicy } from './pages/privacy-policy'
import { TermsConditions } from './pages/terms-conditions'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { MisterioPackageDetails } from './pages/packages/misterio-package-details'
import { OrderCheckout } from './pages/order/order-checkout'
import { OrderDetails } from './pages/order/order-details'
import { OrderList } from './pages/order/order-list'
import { AppShell, createStyles } from '@mantine/core'
import { Misterio } from './pages/misterio/misterio'
import { MisterioStage } from './pages/misterio/misterio-stage'
import { NoPage } from './shared/no-page'
import { Services } from './pages/services'

export const App = ({ instance }: { instance: PublicClientApplication }): JSX.Element => {
  const [dark] = useState(true)

  const useStyles = createStyles(() => ({
    main: {
      padding: 0,
      paddingTop: '56px',
      paddingBottom: '28px',
    },
  }))

  const { classes } = useStyles()

  /**
   * msal-react is built on the React context API and all parts of your app that require authentication must be
   * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
   * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
   * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */
  return (
    <MsalProvider instance={instance}>
      <AppShell
        classNames={{ main: classes.main }}
        aside={<></>}
        header={
          <Toolbar
            links={[
              { label: 'About', link: 'about' },
              {
                label: 'Services',
                link: '/services',
                subLinks: [{ label: 'Packages', link: '/packages' }],
              },
              { label: 'Testimonials', link: '/testimonials' },
              { label: 'Contact & FAQs', link: '/contact-faqs' },
            ]}
            backgroundImage={toolbarImage}
          />
        }
        footer={<MofFooter></MofFooter>}
      >
        <div className={'theme ' + (dark ? 'theme--default' : 'theme--light') + ' base'}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/packages' element={<Packages />} />
            <Route path='/packages/misterio' element={<MisterioPackageDetails />} />
            <Route path='/misterio' element={<Misterio />} />
            <Route path='/misterio/stage/:stage' element={<MisterioStage />} />
            <Route path='/orders' element={<OrderList />} />
            <Route path='/order-checkout' element={<OrderCheckout />} />
            <Route path='/order-details' element={<OrderDetails />} />
            <Route path='/about' element={<About />} />
            <Route path='/testimonials' element={<Testimonials />} />
            <Route path='/services' element={<Services />} />
            <Route path='/contact-faqs' element={<ContactFaqs />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-conditions' element={<TermsConditions />} />

            <Route path='*' element={<NoPage />} />
          </Routes>
        </div>
      </AppShell>
    </MsalProvider>
  )
}
