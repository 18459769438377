import { TileAvatarSize, TileColours, TileStyleType } from './tile'
import { AngularStyle } from '../angular-style'
import { ReactNode } from 'react'
import { createStyles, MediaQuery } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  tileStyle: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      // paddingTop: theme.spacing.md
    },
  },
}))

interface TileStyleOpts {
  style: TileStyleType
  colour: TileColours
  children: ReactNode
  media?: string
  avatar?: string
  avatarSize?: TileAvatarSize
  altColour?: TileColours
  greyscaleMedia?: boolean
}
export const TileStyle = (props: TileStyleOpts): JSX.Element => {
  const { style } = props
  switch (style) {
    default:
    case 'simple-1':
      return <TileStyle1 {...props}></TileStyle1>
    case 'simple-2':
      return <TileStyle2 {...props}></TileStyle2>
    case 'simple-3':
      return <TileStyle3 {...props}></TileStyle3>
    case 'sm-media-1':
      return <TileStyleSmMedia1 {...props}></TileStyleSmMedia1>
    case 'md-media-1':
      return <TileStyleMdMedia1 {...props}></TileStyleMdMedia1>
    case 'md-media-2':
      return <TileStyleMdMedia2 {...props}></TileStyleMdMedia2>
    case 'lg-media-1':
      return <TileStyleLrgMedia1 {...props}></TileStyleLrgMedia1>
    case 'lg-media-2':
      return <TileStyleLrgMedia2 {...props}></TileStyleLrgMedia2>
  }
}

const TileStyle1 = ({ children, ...opts }: TileStyleOpts) => {
  const { classes } = useStyles()

  return (
    <div className={classes.tileStyle}>
      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        {/* Large screen */}
        <AngularStyle {...opts} style={1} side={'left'} size={'sm'}></AngularStyle>
      </MediaQuery>

      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        {/* Small screen */}
        <AngularStyle {...opts} style={5} side={'left'} size={'sm'}></AngularStyle>
      </MediaQuery>

      {/* Content */}
      <div className='tile-style-content'>{children}</div>

      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        {/* Large screen */}
        <AngularStyle {...opts} style={2} side={'right'} size={'sm'}></AngularStyle>
      </MediaQuery>
    </div>
  )
}

const TileStyle2 = ({ children, ...opts }: TileStyleOpts) => {
  const { classes } = useStyles()

  return (
    <div className={classes.tileStyle}>
      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        {/* Large screen */}
        <AngularStyle {...opts} style={2} side={'left'} size={'sm'}></AngularStyle>
      </MediaQuery>

      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        {/* Small screen */}
        <AngularStyle {...opts} style={5} side={'left'} size={'sm'}></AngularStyle>
      </MediaQuery>

      {/* Content */}
      <div className='tile-style-content'>{children}</div>

      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        {/* Small screen */}
        <AngularStyle {...opts} style={4} side={'left'} size={'sm'}></AngularStyle>
      </MediaQuery>

      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        {/* Large screen */}
        <AngularStyle {...opts} style={1} side={'right'} size={'sm'}></AngularStyle>
      </MediaQuery>
    </div>
  )
}

const TileStyle3 = ({ children, ...opts }: TileStyleOpts) => {
  const { classes } = useStyles()

  return (
    <div className={classes.tileStyle}>
      {/* Both angular styles appear to support both desktop/mobile */}
      <AngularStyle {...opts} style={3} side={'left'} size={'sm'}></AngularStyle>

      {/* Content */}
      <div className='tile-style-content'>{children}</div>

      <AngularStyle {...opts} style={3} side={'right'} size={'sm'}></AngularStyle>
    </div>
  )
}

const TileStyleSmMedia1 = ({ children, ...opts }: TileStyleOpts) => {
  const { classes } = useStyles()

  return (
    <div className={classes.tileStyle}>
      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle {...opts} style={5} side={'left'} size={'sm'}></AngularStyle>
      </MediaQuery>

      <div className='tile-style-content sm-media-1'>{children}</div>

      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle {...opts} style={2} side={'right'} size={'sm'}></AngularStyle>
      </MediaQuery>
    </div>
  )
}

const TileStyleMdMedia1 = ({ children, ...opts }: TileStyleOpts) => {
  const { classes } = useStyles()

  return (
    <div className={classes.tileStyle}>
      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle
          {...opts}
          style={2}
          side={'left'}
          size={'md'}
          altOverlayMedia={true}
        ></AngularStyle>
      </MediaQuery>

      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle
          {...opts}
          style={5}
          side={'left'}
          size={'sm'}
          altOverlayMedia={true}
        ></AngularStyle>
      </MediaQuery>

      <div className='tile-style-content md-media-2'>{children}</div>
    </div>
  )
}

const TileStyleMdMedia2 = ({ children, ...opts }: TileStyleOpts) => {
  const { classes } = useStyles()

  return (
    <div className={classes.tileStyle}>
      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle
          {...opts}
          style={5}
          side={'right'}
          size={'sm'}
          altOverlayMedia={true}
        ></AngularStyle>
      </MediaQuery>

      <div className='tile-style-content md-media-2'>{children}</div>

      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle
          {...opts}
          style={2}
          side={'right'}
          size={'md'}
          altOverlayMedia={true}
        ></AngularStyle>
      </MediaQuery>
    </div>
  )
}

const TileStyleLrgMedia1 = ({ children, ...opts }: TileStyleOpts) => {
  const { classes } = useStyles()

  return (
    <div className={classes.tileStyle}>
      <div className='tile-style-content lg-media-1'>{children}</div>

      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle {...opts} style={2} side={'right'} size={'lg'}></AngularStyle>
      </MediaQuery>

      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle {...opts} style={4} side={'left'} size={'sm'}></AngularStyle>
      </MediaQuery>
    </div>
  )
}

const TileStyleLrgMedia2 = ({ children, ...opts }: TileStyleOpts) => {
  const { classes } = useStyles()

  return (
    <div className={classes.tileStyle}>
      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle
          {...opts}
          style={2}
          side={'left'}
          size={'lg'}
          altOverlayMedia={true}
        ></AngularStyle>
      </MediaQuery>

      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        <AngularStyle
          {...opts}
          style={5}
          side={'left'}
          size={'sm'}
          altOverlayMedia={true}
        ></AngularStyle>
      </MediaQuery>

      <div className='tile-style-content lg-media-2'>{children}</div>
    </div>
  )
}
