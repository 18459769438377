import { TileAvatarSize, TileColours } from './tile' // Todo: Thing about this types being used here...

export const Avatar = ({
  img,
  size,
  colour,
  greyscale,
}: {
  img: string
  size: TileAvatarSize
  colour: TileColours
  greyscale?: boolean
}) => (
  <div
    className={`avatar ${size} ${colour}`}
    style={greyscale ? { filter: 'grayscale(100%)' } : {}}
  >
    <img src={img} alt='Avatar' className={`avatar-img ${size}`}></img>
  </div>
)
