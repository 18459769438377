/* eslint-disable quotes */
import { CarouselCardProps } from '../../components/carousel'

import toolbarImg from '../../assets/photos/smoke_1.jpg'
import homeImg1 from '../../assets/photos/home_carousel/img_1.jpg'
import homeImg2 from '../../assets/photos/home_carousel/img_2.jpg'
import homeImg3 from '../../assets/photos/home_carousel/img_3.jpg'
import homeImg4 from '../../assets/photos/home_carousel/img_4.jpg'
import homeImg5 from '../../assets/photos/home_carousel/img_5.jpg'
import { Tile, TileOpts } from '../../components/tile'
import avatarImg from '../../assets/photos/avatar_2.jpg'
import testimonialImg from '../../assets/photos/IMG_3748_edit.jpg'
import { Button, createStyles } from '@mantine/core'
import { Link } from 'react-router-dom'

const splashGradient = 'linear-gradient(to top, #232323f2, #000000a3)'

const customh1SmallerThanXl = {
  h1: {
    fontSize: 'calc(22px * 2) !important',
  },
}

const useStyles = createStyles((theme) => ({
  avatarTilewrapper: {
    height: '800px',
    [theme.fn.smallerThan('sm')]: {
      height: '100%',
    },
  },
  smAvatarTilewrapper: {
    height: '500px',
    [theme.fn.smallerThan('sm')]: {
      height: '100%',
    },
  },
  shortAboutTile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    padding: '0 2em',
    maxWidth: '650px',
    [theme.fn.smallerThan('lg')]: {
      padding: '0 4em',
      [theme.fn.smallerThan('sm')]: {
        padding: `0 ${theme.spacing.sm}px`,
        paddingTop: `${theme.spacing.lg}px`,
      },
    },
  },
  shortPackagesTile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '1em',
    h1: {
      textAlign: 'center',
      margin: '0 0.6em',
    },
    [theme.fn.smallerThan('xl')]: customh1SmallerThanXl,
  },
  shortTestimonialsTile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    padding: '2em 4em',
    h1: {
      textAlign: 'center',
      margin: '0 0.6em',
    },
    [theme.fn.smallerThan('xl')]: {
      ...customh1SmallerThanXl,
      [theme.fn.smallerThan('sm')]: {
        padding: '0',
      },
    },
  },
  shortContactFaqsTileWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  shortContactFaqsTile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    height: '100%',
    maxWidth: '60%',
    padding: '1em',
    h1: {
      textAlign: 'center',
      margin: '0 0.6em',
    },
    [theme.fn.smallerThan('xl')]: {
      ...customh1SmallerThanXl,
      maxWidth: 'none',
      padding: '0 2em'
    }    
  },
}))

// Below is req. for build on github action
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const splashTextStyles = createStyles((theme) => ({
  splashTextWrapper: {
    h1: {
      margin: '0.2em 0'
    },
    h3: {
      [theme.fn.smallerThan('sm')] : {
        fontSize: `${theme.fontSizes.xl}px !important`
      }
    }
  },
}))

export const carouselImages: CarouselCardProps[] = [
  {
    image: `${splashGradient},url("${homeImg1}")`,
    category: '',
    title: '',
  },
  {
    image: `${splashGradient},url("${homeImg2}")`,
    category: '',
    title: '',
  },
  {
    image: `${splashGradient},url("${homeImg3}")`,
    category: '',
    title: '',
  },
  {
    image: `${splashGradient},url("${homeImg4}")`,
    category: '',
    title: '',
  },
  {
    image: `${splashGradient},url("${homeImg5}")`,
    category: '',
    title: '',
  },
]

export const toolbarImage = `url("${toolbarImg}")`

export const HomeSplashText = (): JSX.Element => {
  const { classes } = splashTextStyles()

  return (
    <div className={classes.splashTextWrapper}>
      <p>
        Offering personal training, calisthenic coaching, nutrition and fitness plans, and weight gain
        coaching operating out of Simply Gym Coventry.
      </p>

      <br />

      <p>Helping you become the best version of yourself.</p>
    </div>
  )
}

export const ShortAboutTile = (): JSX.Element => {
  const tileTest1: TileOpts = {
    style: 'lg-media-1',
    colour: 'bg-primary',
    accent: 'bg-tertiary',
    avatar: avatarImg,
  }

  const { classes } = useStyles()

  return (
    <div className={classes.avatarTilewrapper}>
      <Tile tileOpts={tileTest1} h={'100%'}>
        <div className={classes.shortAboutTile}>
          <h2>Meet Your Personal Trainer</h2>

          <p>
            I&apos;m Rik, a dedicated personal trainer committed to helping you achieve your health and
            fitness goals. Fitness has always been my passion, and after years of personal experience
            and professional training, I&apos;ve made it my mission to guide others on their journey to
            better health.
          </p>

          <Link to='/about' style={{ margin: '1.2em' }}>
            <Button
              variant='white'
              radius='xl'
              size='md'
              styles={{
                root: { padding: '0 14', height: 48 },
              }}
            >
              Find out more about me
            </Button>
          </Link>
           <p>
              If you&apos;re looking for a calisthenics personal trainer in the Coventry area, or aiming to improve strength
              and endurance with weight lifting and nutrition advice, I&apos;m here to support you every step of the way.
            </p>
            < br/>
          <p>
            Take a look at my <Link to='/testimonials'>Testimonials</Link> page to learn more about
            how I have helped others achieve their fitness goals.
          </p>
        </div>
      </Tile>
    </div>
  )
}

export const ShortPackagesTile = (): JSX.Element => {
  const tileTest2: TileOpts = {
    style: 'simple-3',
    colour: 'brand-tertiary',
    accent: 'bg-tertiary',
  }

  const { classes } = useStyles()

  return (
    <Tile tileOpts={tileTest2}>
      <div className={classes.shortPackagesTile}>
        <h2>Personalised Fitness Training Tailored to You</h2>
        <p>
          I understand that everyone&apos;s fitness journey is unique. That&apos;s why I offer tailored programmes designed
          to meet your individual needs. From personal training for beginners to advanced strength training, every
          session is crafted to deliver measurable results.
        </p>

        <h2>Why Choose Mistry of Fitness?</h2>
        <ul>
          <li><strong>Expert Personal Training</strong>: Focused on your unique goals and fitness level.</li>
          <li><strong>Calisthenics Personal Training</strong>: Build strength, flexibility, and control through bodyweight exercises.</li>
          <li><strong>Weight Gain Training and Nutrition Advice</strong>: Programmes designed to help you gain weight and increase your muscle mass.</li>
          <li><strong>Strength Training</strong>: Tailored plans to boost your endurance and power.</li>
          <li>
          <strong>Convenient Location</strong>: I hold my personal training sessions at Simply Gym Coventry, so if
            you are looking for a local personal trainer, <Link to='/contact-faqs'>get in touch today</Link>.
          </li>
        </ul>
        <Link to='/packages' style={{ margin: '1.2em' }}>
          <Button
            variant='light'
            // rightIcon={<IconLink size={20} stroke={1.5} />}
            radius='xl'
            size='md'
            styles={{
              root: { padding: '0 14', height: 48 },
            }}
          >
            Learn more about packages and prices
          </Button>
        </Link>
      </div>
    </Tile>
  )
}

export const ShortTestimonialsTile = (): JSX.Element => {
  const tileTest2: TileOpts = {
    style: 'lg-media-2',
    colour: 'brand-primary',
    accent: 'bg-secondary',
    avatar: testimonialImg,
    avatarSize: 'xl',
    greyscaleMedia: true,
  }

  const { classes } = useStyles()

  return (
    <div className={classes.smAvatarTilewrapper}>
      <Tile tileOpts={tileTest2} h={'100%'}>
        <div className={classes.shortTestimonialsTile}>
          <h2 style={{ padding: '24px' }}>{"Don't Just take my word for it."}</h2>
          <Link to='/testimonials' style={{ margin: '1.2em', alignSelf: 'flex-start' }}>
            <Button
              variant='white'
              radius='xl'
              size='md'
              styles={{
                root: { padding: '0 14', height: 48 },
              }}
            >
              View Testimonials
            </Button>
          </Link>
        </div>
      </Tile>
    </div>
  )
}

export const ShortContactFaqsTile = (): JSX.Element => {
  const tileTest2: TileOpts = {
    style: 'simple-1',
    colour: 'brand-tertiary',
    accent: 'bg-primary',
  }

  const { classes } = useStyles()

  return (
    <Tile tileOpts={tileTest2}>
      <div className={classes.shortContactFaqsTileWrapper}>
      <div className={classes.shortContactFaqsTile}>
        <h2>Frequently Asked Questions</h2>
        <p><strong>1. Is personal training suitable for beginners?</strong><br />
        Absolutely! I specialise in working with clients at all fitness levels, including those who are just starting out.
        </p>
        <p><strong>2. Where are sessions held?</strong><br />
        Training sessions are conveniently based at Simply Gym in Coventry - near Costco.
        </p>
        <p><strong>3. How can I get started?</strong><br />
        Contact me today to schedule your initial consultation, and let&apos;s plan your fitness journey together. You can also read all about the packages and prices I offer <Link to='/packages'>here</Link>.
        </p>
        
        <h2>Take the First Step Towards a Healthier You</h2>
        <p>
          Whether you&apos;re looking for a local strength trainer in Coventry, exploring calisthenics
          coaching sessions, or focusing on weight gain training, I&apos;m here to help you every step of
          the way.
        </p>

        <Link to='/contact-faqs' style={{ margin: '1.2em' }}>
          <Button
            variant='light'
            radius='xl'
            size='md'
            styles={{
              root: { padding: '0 14', height: 48 },
            }}
          >
            Contact Now
          </Button>
        </Link>
      </div>
      </div>
    </Tile>
  )
}
