/* eslint-disable no-irregular-whitespace */
import { Badge, Button, createStyles, Flex, Grid, Text, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import { Splash, splashClassProps, SplashTitle } from '../../components/splash'
import { Tile, TileOpts } from '../../components/tile'
import img1 from '../../assets/pages/packages/IMG_3857.jpg'
import misterioLogo from '../../assets/misterio_logo.png'
import { Link } from 'react-router-dom'
import { MofCard } from '../../components/card'

const useStyles = createStyles((theme) => ({
  splash: {
    ...splashClassProps,
    [theme.fn.smallerThan('sm')]: {
      backgroundPositionX: '-200px !important',
    },
  },

  packagesPageWrapper: {},

  packagesIntro: {
    padding: theme.spacing.md,
  },

  packageDetails: {
    paddingBottom: `${theme.spacing.lg}px`,
  },

  packageGrid: {
    [theme.fn.smallerThan('sm')]: {
      padding: 0,
    },
  },

  gridCol: {
    [theme.fn.largerThan('lg')]: {
      minHeight: '525px', // ensures cards will maintain grid look on larger screens
    },
  },

  packageTitle: {
    textWrap: 'pretty',
    fontWeight: 400,
    fontSize: '36px',
    margin: '0 !important',
    marginBottom: '0.8em !important',
    [theme.fn.smallerThan('sm')]: {
      margin: '0.4em !important',
      fontSize: '28px',
      textWrap: 'nowrap', // ensure cards grow on smaller screens
    },
  },

  packageList: {
    li: {
      [theme.fn.smallerThan('sm')]: {
        fontSize: '0.9em',
      },
    },
  },

  brandNewBadge: {
    position: 'absolute',
    transform: 'translate(110px, -8px)',
    fontSize: theme.fontSizes.xs,
  },

  misterioImg: {
    objectFit: 'contain',
    width: '100%',
    height: '200px',
    [theme.fn.smallerThan('sm')]: {
      height: '120px',
    },
  },
}))

export const Packages = (): JSX.Element => {
  const theme = useMantineTheme()
  const isSmallerThanXl = useMediaQuery(`(max-width: ${theme.breakpoints.xl}px)`)

  const bottomTile: TileOpts = {
    style: 'simple-1',
    colour: 'bg-primary',
    accent: 'brand-secondary',
  }

  const { classes } = useStyles()

  const paraPt1 =
    // eslint-disable-next-line quotes
    "Lets talk about what you can expect from myself and what's included."

  const paraPt2 =
    // eslint-disable-next-line quotes
    "Unlike a lot of fitness professionals, I don't have lots of different packages. I know that your time is precious, and want you to get the most out of your training with me. That's I why I have designed the Hybrid approach! This allows you to be flexible with your training whilst still having myself to guide, support and keep you on track."

  const paraPt3 =
    // eslint-disable-next-line quotes
    'Whether you have one to one sessions or opt in for the Hybrid program, you will receive the same level of attention, focus to detail and professionalism in your sessions. '

  return (
    <div className='packages-page'>
      <title>Packages | Personal Training Prices Coventry | Mistry of Fitness</title>
      <meta
        name='description'
        content='Discover flexible fitness packages | Personal training, calisthenic workout plans, weight gain coaching, and salsa dance classes | Get started today →'
      />

      <Splash
        height={'calc(100vh - 56px)'}
        backgroundSrc={`url(${img1})`}
        className={classes.splash}
      >
        <SplashTitle>Personal Training Prices in Coventry</SplashTitle>
      </Splash>

      <Tile tileOpts={bottomTile}>
        <Flex direction={'column'} m={{ base: 'md', lg: 'xl' }}>
          <Flex className={classes.packagesIntro} direction={'column'}>
            <p>{paraPt1}</p>
            <br />
            <p>{paraPt2}</p>
            <br />
            <p>{paraPt3}</p>​
          </Flex>

          <Flex
            className={classes.packageDetails}
            direction={{ md: 'row', base: 'column' }}
            w={'100%'}
            gap={'xl'}
          >
            <Grid
              className={classes.packageGrid}
              grow={isSmallerThanXl}
              gutter={'md'}
              gutterMd={'xl'}
            >
              <Grid.Col span={6} className={classes.gridCol}>
                <MofCard>
                  <p className={classes.packageTitle}>1 to 1 Session - £45</p>

                  <ul className={classes.packageList}>
                    <li>1 in-person personal training session</li>
                    <li>Session will include and warm up, cool down and last 1 hour</li>
                  </ul>
                </MofCard>
              </Grid.Col>

              <Grid.Col span={6} className={classes.gridCol}>
                <MofCard>
                  <p className={classes.packageTitle}>Misterio Programme</p>

                  <img className={classes.misterioImg} src={misterioLogo}></img>

                  <Link to='/packages/misterio' style={{ margin: '1.2em' }}>
                    <Button
                      radius='xl'
                      size='md'
                      className='purchaseMisterioBtn'
                      w={'100%'}
                      variant='white'
                    >
                      Click here to find out more!
                    </Button>
                  </Link>
                </MofCard>
              </Grid.Col>

              <Grid.Col span={6} className={classes.gridCol}>
                <MofCard>
                  <p className={classes.packageTitle}>Hybrid Coaching - £225</p>

                  <Flex direction={'column'} align={'center'}>
                    <ul className={classes.packageList}>
                      <li>Full-time one-on-one coaching</li>
                      <li>1 in-person personal training session with me per week</li>
                      <li>Contact via WhatsApp and messenger 24/6 support</li>
                      <li>Calorie and nutritional monitoring and support (twice per week)</li>
                      <li>Customised workout program design with continual monitoring</li>
                      <li>Habit creation to help create your new lifestyle</li>
                      <li>A weekly pop-up of my head.</li>
                    </ul>
                    <Text fz='sm' m={'sm'}>
                      (All sessions must be used within 4 weeks)
                    </Text>
                  </Flex>
                </MofCard>
              </Grid.Col>

              <Grid.Col span={6} className={classes.gridCol}>
                <MofCard>
                  <p className={classes.packageTitle}>MOF Online - £125</p>

                  <ul className={classes.packageList}>
                    <li>
                      A 4 week week personalised programme aligning with your goals and lifestyle
                    </li>
                    <li>Calorie and nutritional monitoring and support</li>
                    <li>Habit creation to help create your new lifestyle</li>
                    <li>A Weekly check in to make sure you&apos;re staying on track</li>
                  </ul>
                </MofCard>
              </Grid.Col>

              <Grid.Col span={6} className={classes.gridCol}>
                <MofCard>
                  <Badge
                    className={classes.brandNewBadge}
                    variant='gradient'
                    gradient={{ from: 'red', to: '#232323' }}
                  >
                    Brand New
                  </Badge>

                  <p className={classes.packageTitle}>Complete Mistry - £425</p>

                  <ul className={classes.packageList}>
                    <li>12 in-person personal training session with me</li>
                    <li>Full-time one-on-one coaching</li>
                    <li>Contact via WhatsApp and messenger 24/6 support</li>
                    <li>Calorie and nutritional monitoring and support</li>
                    <li>Habit creation to help create your new lifestyle</li>
                  </ul>

                  <Text fz='sm' m={'sm'}>
                    (All sessions must be used within a 4 week period)
                  </Text>
                </MofCard>
              </Grid.Col>
            </Grid>
          </Flex>
        </Flex>
      </Tile>
    </div>
  )
}
